import React, { useEffect, useState } from 'react';
import * as S from './FooterV2.styles';
import { addDataLayer } from '../../utils/dataLayer';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import { nanoid } from 'nanoid';
import { Image } from '@builder.io/react';

const Footer = props => {
  const {
    disclaimerText,
    usedHamburgerToggle,
    socialNetworks,
    linkGroup,
    logo,
    newsletterTitle,
    newsletterBlurb,
    backgroundColour
  } = props;
  const [subsEmail, setSubsEmail] = useState('');
  const [mailingListSignup, setMailingListSignup] = useState(false);
  const [submit, setSubmit] = useState(false);
  useEffect(() => {
    if (submit) {
      fetch(
        `${process.env.GATSBY_SUBSCRIPTIONS_API_URL}/api/subscribeToNewsletter`,
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: subsEmail,
            store: 2,
            language: 'en'
          })
        }
      )
        .then(res => {
          res.json();
          setSubmit(false);
        })
        .then(() => {
          setSubsEmail('Thanks for signing up!');
          setMailingListSignup(true);
          footerTracking('footer_newsletter_submit_success');
        })
        .catch(err => {
          console.error('error', err);
          footerTracking('footer_newsletter_submit_failed');
        });
    }
  });
  const handleForm = e => {
    e.preventDefault();
    setSubmit(true);
  };

  const footerTracking = (action, label) => {
    const trackingObject = {
      event: 'footer_action',
      action
    };
    label && (trackingObject.label = label);
    addDataLayer(trackingObject);
  };
  const scrollToTopEvent = () => {
    addDataLayer({
      event: 'scroll_to_top_action',
      action: 'scroll_to_top_clicked',
      label: `${window?.location.pathname}`
    });
    window?.scroll(0, 0);
  };

  return (
    <S.Container backgroundColour={backgroundColour}>
      <S.ButtonUp onClick={scrollToTopEvent} />
      <S.InnerContainer>
        <S.MainFlexContainer>
          <S.Logo onClick={() => footerTracking('footer_logo_clicked')}>
            <Image src={logo ?? '/images/petlab.svg'} alt={'PetLab logo'} />
          </S.Logo>
          <S.NewsletterContainer>
            <S.Newsletter>
              <S.NewsletterTop>
                <S.NewsletterHeader>{newsletterTitle}</S.NewsletterHeader>
                <S.NewsletterBlurb
                  dangerouslySetInnerHTML={{ __html: newsletterBlurb }}
                ></S.NewsletterBlurb>
              </S.NewsletterTop>
              <S.Subscribe>
                <S.MailingListForm onSubmit={handleForm}>
                  {submit ? (
                    <S.MailingListFormInputsContainer processing>
                      <S.MailingListTextInputProcessing
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email address"
                        className="email"
                        value={subsEmail}
                        readOnly={true}
                        onChange={e => setSubsEmail(e.target.value)}
                        required
                      />
                      <S.MailingListSubmitProcessing
                        type="submit"
                        className="submit"
                        disabled={true}
                      >
                        <LottieAnimation
                          autoplay={true}
                          loop={true}
                          animationPath={'/images/footer-icons/ball.json'}
                          className="lottie-animation-wrapper"
                        />
                      </S.MailingListSubmitProcessing>
                    </S.MailingListFormInputsContainer>
                  ) : (
                    <>
                      {mailingListSignup ? (
                        <S.MailingListFormInputsContainer confirmed>
                          <S.MailingListTextInputConfirmation>
                            <p>Thanks for signing up!</p>
                          </S.MailingListTextInputConfirmation>
                          <S.MailingListSubmitConfirmation
                            type="submit"
                            className="submit"
                            disabled={true}
                          >
                            <Image
                              src="/images/footer-icons/done_24px.svg"
                              alt="a completed icon"
                            />
                          </S.MailingListSubmitConfirmation>
                        </S.MailingListFormInputsContainer>
                      ) : (
                        <>
                          <S.MailingListFormInputsContainer>
                            <S.MailingListTextInput
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email address"
                              className="email"
                              value={subsEmail}
                              onChange={e => setSubsEmail(e.target.value)}
                              required
                            />

                            <S.MailingListSubmit
                              type="submit"
                              className="submit"
                              disabled={submit}
                              onClick={() =>
                                footerTracking('footer_newsletter_submit')
                              }
                            >
                              <Image
                                src="/images/footer-icons/right-arrow-rebrand-footer.svg"
                                alt="a slim right arrow"
                              />
                            </S.MailingListSubmit>
                          </S.MailingListFormInputsContainer>
                        </>
                      )}
                    </>
                  )}
                </S.MailingListForm>
              </S.Subscribe>

              <S.SocialNetworks>
                {socialNetworks &&
                  socialNetworks.map(el => {
                    const id = nanoid();
                    return (
                      <a
                        onClick={() =>
                          footerTracking(
                            `footer_social_icon_clicked_${el.name.toLowerCase()}`,
                            `${el.name} Icon`
                          )
                        }
                        href={el.link}
                        key={id}
                      >
                        <Image src={el.image} />
                      </a>
                    );
                  })}
              </S.SocialNetworks>
            </S.Newsletter>
          </S.NewsletterContainer>

          {linkGroup &&
            linkGroup.map(({ groupTitle, links }, index) => {
              const id = nanoid();
              return (
                <S.LinkColumn key={id} className={index < 1 ? 'first' : ''}>
                  <S.ColumnHeader>{groupTitle}</S.ColumnHeader>
                  <S.LinkList>
                    {links.map(({ linkName, linkUrl }) => {
                      const linkId = nanoid();
                      return (
                        <S.LinkItem
                          onClick={e =>
                            footerTracking(
                              'footer_link_clicked',
                              e.target.textContent
                            )
                          }
                          key={linkId}
                        >
                          <a href={linkUrl}>{linkName}</a>
                        </S.LinkItem>
                      );
                    })}
                  </S.LinkList>
                </S.LinkColumn>
              );
            })}
        </S.MainFlexContainer>

        <S.Separator />

        <S.CopyRightPayments>
          <S.CopyRight>
            &copy; {new Date().getFullYear()} PetLab Co.
          </S.CopyRight>

          <S.PaymentProviders>
            <p>Pay Securely With</p>

            <ul>
              <li className={'first-align'}>
                <Image src="/images/footer-icons/visa.svg" alt="visa image" />
              </li>
              <li>
                <Image
                  src="/images/footer-icons/mastercard.svg"
                  alt="mastercard image"
                />
              </li>
              <li>
                <Image src="/images/footer-icons/amex.svg" alt="amex image" />
              </li>
              <li>
                <Image
                  src="/images/footer-icons/paypal.svg"
                  alt="paypal image"
                />
              </li>
              <li>
                <Image
                  src="/images/footer-icons/discover-network.svg"
                  alt="discover network image"
                />
              </li>
            </ul>
          </S.PaymentProviders>
        </S.CopyRightPayments>
        {disclaimerText && (
          <S.DisclaimerText
            usedHamburgerToggle={usedHamburgerToggle}
            dangerouslySetInnerHTML={{
              __html: disclaimerText
            }}
          />
        )}
      </S.InnerContainer>
    </S.Container>
  );
};

export default Footer;
