import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  margin: 0 0 0 0;
  padding: ${({ isProductPage }) =>
    isProductPage ? '0px' : '0 clamp(10px, 5%, 100px)'};
  a {
    text-decoration: none;
    color: #fff !important;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  background: ${({ backgroundColour }) =>
    backgroundColour ?? 'var(--petlab-blue)'};

  .lottie-animation-wrapper {
    width: 34px;
    height: 34px;
  }
`;
export const InnerContainer = styled.div`
  max-width: ${({ isProductPage }) => (isProductPage ? '1200px' : '1280px')};
  margin: auto;
`;

export const MainFlexContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'news news' '. .' '. .';
  grid-gap: 16px;
  padding: 24px 10px 0 10px;

  @media (min-width: 641px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'news  news .' '. . .';
  }
  @media (min-width: 999px) {
    grid-column-gap: 2rem;
    grid-template-columns: 156px 311px 1fr 1fr 1fr;
    grid-template-areas: 'logo news . . .';
  }
`;

export const NewsletterContainer = styled.div`
  grid-area: news;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media (min-width: 999px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 320px;

  a {
    color: #fff;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;

    div {
      :first-of-type {
        margin-right: 128px;
      }
      :nth-of-type(2n) {
        margin-right: 86px;
      }
      :last-of-type {
        margin-right: 80px;
      }
    }
  }
`;

export const ColumnHeader = styled.h3`
  color: #fcf1e0;
  text-transform: uppercase;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.16em;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: normal;
  text-align: left;
`;

export const Logo = styled.div`
  display: none;
  grid-area: logo;
  @media (min-width: 999px) {
    display: flex;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const NewsletterTop = styled.div`
  padding-left: 3px;
`;

export const NewsletterHeader = styled.h3`
  color: #fcf1e0;
  text-transform: uppercase;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.16em;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: normal;
  text-align: left;
`;

export const NewsletterBlurb = styled.p`
  font: 16px/23px Utopia Std;
  font-weight: normal;
  color: #fff;
  text-align: left;
  margin-bottom: 8px;
`;

export const Newsletter = styled.div`
  flex: 1;
  padding: 0;
  width: 100%;

  @media (min-width: 1280px) {
    margin-top: 0;
    min-width: 311px;
  }
`;

export const Subscribe = styled.div`
  padding: 0;
  @media (min-width: 1280px) {
    margin: 0 auto;
  }
`;

export const MailingListForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  border-radius: 20px;
`;

export const MailingListFormInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  flex-wrap: nowrap;
  width: 100%;
  background: ${({ confirmed, processing }) =>
    confirmed ? '#D3EDEA' : processing ? '#E6E8F1' : '#E6E8F1'};
  border-radius: 20px;
`;

export const MailingListTextInput = styled.input`
  flex: 1;
  height: 40px;
  padding: 10px 0 7px 16px;
  border: none;
  color: #6677aa;
  background: #e6e8f1;
  border-radius: 45px 0 0 45px;
  font-size: 16px;
  font-family: 'Utopia Std', serif;

  :focus {
    color: #001c72;
  }

  @media (min-width: 1280px) {
    padding-left: 16px;
  }
`;

export const MailingListTextInputProcessing = styled.input`
  flex: 1;
  height: 40px;
  padding: 10px 0 7px 16px;
  border: none;
  color: #001c72;
  background: #e6e8f1;
  border-radius: 45px 0 0 45px;
  font-size: 16px;
  font-family: 'Utopia Std', serif;

  @media (min-width: 1280px) {
    padding-left: 16px;
  }
`;

export const MailingListTextInputConfirmation = styled.div`
  flex: 1;
  height: 40px;
  padding: 10px 0 7px 16px;
  border: none;
  color: #001c72;
  opacity: 1;
  border-radius: 45px 0 0 45px;
  font-size: 16px;
  font-family: 'Utopia Std', serif;

  p {
    color: #001c72;
    margin: 0;
  }

  @media (min-width: 1280px) {
    padding-left: 16px;
  }
`;

export const MailingListSubmit = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: background 0.2s;
  height: 34px;
  width: 34px;
  border: none;
  border-radius: 45px;
  margin: 3px 3px 3px 3px;
  background-color: #bc2e3e;
  cursor: pointer;
  img {
    transition: 0.2s;
  }

  &:hover {
    background-color: #c95865;
    img {
      margin-left: 8px;
    }
  }
  :focus {
    border: 6px solid #f2d5d8;
    img {
      margin-left: 0;
    }
  }
  img {
    width: 18px;
  }
`;

export const MailingListSubmitProcessing = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: 0.2s;
  height: 34px;
  width: 34px;
  border: none;
  border-radius: 45px;
  margin: 3px 3px 3px 3px;
  cursor: default;
  background: none;

  img {
    width: 17px;
    background: none;
  }
`;

export const MailingListSubmitConfirmation = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #d3edea;

  height: 34px;
  width: 34px;
  border: none;
  border-radius: 45px;
  margin: 3px 3px 3px 3px;
  cursor: default;

  img {
    width: 17px;
  }
`;

export const MailingListConfirmation = styled.p`
  margin: 0 auto;
  padding-left: 3px;
  font-size: 14px;
  padding-top: 9px;
  color: #fcf1e0;
  font: 16px/23px Utopia Std;
`;

export const SocialNetworks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 19px 0 0 6px;

  a {
    margin-right: 15px;
    picture,
    img {
      width: 18px;

      :hover {
        opacity: 0.8;
      }
    }
  }
`;

export const LinkColumn = styled.div`
  flex: 1;
  text-align: left;
  flex-direction: column;
  flex-wrap: wrap;
  &.first {
    grid-column: 1;
    @media (min-width: 999px) {
      grid-column: auto;
    }
  }
  @media (min-width: 999px) {
    justify-self: center;
    flex-direction: row;
    text-align: left;
  }
`;

export const LinkList = styled.ul``;

export const LinkItem = styled.li`
  margin-bottom: 8px !important;
  a {
    color: #fff;
    letter-spacing: 0;
    font: 16px/23px 'Utopia Std';
    text-decoration: none;
    white-space: nowrap;

    :hover {
      text-decoration: underline;
    }
  }
  @media (min-width: 1280px) {
    a {
      font-size: 16px;
      line-height: 30px;
    }
  }
`;

export const Separator = styled.div`
  max-width: 1280px;
  margin-top: 25px;
  border-top: 1px solid rgba(252, 241, 224, 0.2);

  @media (min-width: 1280px) {
    margin: 25px auto 0;
  }
`;

export const CopyRightPayments = styled.div`
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 641px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const DisclaimerText = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'P22-Underground Book', sans-serif;
  font-weight: 100;
  font-size: 10px;
  color: rgba(255, 255, 255, 1);

  @media (max-width: 1100px) {
    padding-bottom: ${({ usedHamburgerToggle }) =>
      usedHamburgerToggle ? '70px' : '10px'};
  }

  p {
    line-height: 1.2;
  }
`;

export const CopyRight = styled.p`
  margin: 30px 0;
  order: 2;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  color: #fff;
  @media (min-width: 641px) {
    margin: 0;
  }
  @media (min-width: 1280px) {
    text-align: right;
    order: 1;
    margin: 0 0;
  }
`;

export const PaymentProviders = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  order: 1;

  ul {
    display: flex;
    align-items: center;

    li {
      display: inline-block;
      list-style-type: none;
      align-items: center;
      justify-content: center;
      height: 25px;
      margin-bottom: 0 !important;
      margin-left: 8px;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 12px;
  }

  @media (min-width: 641px) {
    text-align: left;
    display: flex;
    flex-direction: row;
  }
`;

export const ButtonUp = styled.button`
  cursor: pointer;
  position: absolute;
  right: 4%;
  margin-top: -25px;
  width: 48px;
  height: 48px;
  border-style: hidden;
  border-radius: 50%;
  background-image: url('/images/upright_chevron.svg');
  background-size: 100%;
`;
